import { FullScreenIcon, FullScreenWrapper } from './ChartTable'

import Loader from 'components/Loader'
import React from 'react'

import _ from 'lodash'
import styled from 'styled-components/macro'
import { toChecksum } from 'state/logs/utils'
import { useActiveWeb3React } from 'hooks/web3'
import useTheme from 'hooks/useTheme'
import { useIsMobile } from './SelectiveChartingPair'

const Wrapper = styled.div`
  *,
  > * {
    .css-14hxyhp,
    [data-is-custom-header-element] {
      display: none !important;
    }
  }
  [data-is-custom-header-element] {
    display: none !important;
  }
`
type ChartProps = {
  symbol: string
  address: string
  pairData?: any[]
  height?: number
  pairAddress?: string
  tokenSymbolForChart?: string
  networkProvided?: string
}

export const ChartComponent = (props: ChartProps) => {
  const { chainId } = useActiveWeb3React()
  const { height, networkProvided, address, pairAddress: pairAddy, symbol, pairData } = props
  const isMobile = useIsMobile()

  const pairAddress = React.useMemo(() => {
    if (pairAddy) return pairAddy
    if (!pairData?.length) return
    const pairId = pairData?.[0]?.id
    return pairId
  }, [pairData, symbol, pairAddy])

  const chartURL = React.useMemo(() => {
    const network = networkProvided
      ? networkProvided
      : !chainId || chainId == 1
      ? 'ethereum'
      : chainId == 56
      ? 'bsc'
      : 'ethereum'
    console.log(`[chartURL]`, network)
    return `https://dexscreener.com/${network}/${toChecksum(pairAddress)}?embed=1&trades=0&info=0`
  }, [chainId, networkProvided, pairAddress])

  const theme = useTheme()
  const [isMaxxed, setIsMaxxed] = React.useState(false)
  const heightForChart = !isMaxxed ? (height ? (isMobile ? height / 2 : height) : isMobile ? 400 : 400) : '100vh'
  const changeFn = (newMaxxed: boolean) => setIsMaxxed(newMaxxed)
  if (!pairAddress) {
    return (
      <div style={{ color: theme.text1, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
        <Loader />
        Loading Chart..
      </div>
    )
  }

  return (
    <FullScreenWrapper
      onMaxChange={changeFn}
      childrenFn={({ isMaxxed, ref, onRequest, onExit, isEnabled, onToggle }) => (
        <Wrapper ref={ref} style={{ overflow: 'hidden', height: heightForChart }}>
          <div style={{ height: 0, width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {!isMobile &&
              FullScreenIcon(isMaxxed, isEnabled, onToggle, onRequest, onExit, {
                position: 'relative',
                color: '#ccc',
                top: 20,
                right: 88,
              })}
          </div>
          <iframe
            src={chartURL}
            style={{
              zIndex: 1,
              background: 'transparent',
              border: '1px solid transparent',
              height: typeof heightForChart == 'number' ? heightForChart + 40 : 'calc(100vh + 40px)',
              borderRadius: 4,
              width: '100%',
            }}
          />
        </Wrapper>
      )}
    />
  )
}
