import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useActiveWeb3React } from 'hooks/web3'
import styled from 'styled-components/macro'
import { AutoColumn } from 'components/Column'
import { HeaderText, SubheaderText } from 'pages/Swap'

const PortfolioWrapper = styled(AutoColumn)`
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  width: 100%;
  min-width: 45%;
  max-width: 480px;
  background: ${({ theme }) => theme.bg0};
`

const PortfolioTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins';

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    opacity: 0.5;
  }

  td {
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
  }

  tr:nth-child(even) {
    background: ${({ theme }) => theme.bg1};
  }

  td:last-child {
    font-size: 16px;
  }
`

interface PortfolioPosition {
  id: string
  asset: {
    symbol: string
    name: string
  }
  quantity: string
  value: {
    value: number
  }
}

export const Portfolio: React.FC = () => {
  const [positions, setPositions] = useState<PortfolioPosition[]>([])
  const [loading, setLoading] = useState(true)

  const { account } = useActiveWeb3React() // Get the account from the hook

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get('http://localhost:8080/portfolio', {
          params: { account }, // Pass the account as a query parameter
        })
        const data = response.data.data
        const mappedPositions = data.map((item: any) => ({
          id: item.id,
          asset: {
            symbol: item.attributes.fungible_info.symbol,
            name: item.attributes.fungible_info.name,
          },
          quantity: item.attributes.quantity.float,
          value: {
            value: item.attributes.value,
          },
        }))
        setPositions(mappedPositions)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching positions:', error)
        setLoading(false)
      }
    }

    if (account) {
      // Fetch positions only if account is available
      fetchPositions()
    } else {
      setLoading(false)
    }
  }, [account])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <HeaderText>Portfolio </HeaderText>
      <SubheaderText>Andy is learning</SubheaderText>
      <PortfolioWrapper>
        <PortfolioTable>
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position) => (
              <tr key={position.id}>
                <td>{position.asset.symbol}</td>
                <td>{position.asset.name}</td>
                <td>{position.quantity}</td>
                <td>{position.value.value.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </PortfolioTable>
      </PortfolioWrapper>
    </>
  )
}
