import { Token, WETH9 } from '@uniswap/sdk-core'
import { USDC, USDT } from 'constants/tokens'

import { TokenInfo } from '@uniswap/token-lists'
import _ from 'lodash'
import { isAddress } from '../../utils'
import { useMemo } from 'react'

const alwaysTrue = () => true

/**
 * Create a filter function to apply to a token for whether it matches a particular search query
 * @param search the search query to apply to the token
 */
export function createTokenFilterFunction<T extends Token | TokenInfo>(search: string): (tokens: T) => boolean {
  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    const lower = searchingAddress.toLowerCase()
    return (t: T) => ('isToken' in t ? searchingAddress === t.address : lower === t.address.toLowerCase())
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) return alwaysTrue

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return ({ name, symbol }: T): boolean => Boolean((symbol && matchesSearch(symbol)) || (name && matchesSearch(name)))
}

export function filterTokens<T extends Token | TokenInfo>(tokens: T[], search: string): T[] {
  if (!search) return tokens
  const result = tokens.filter(createTokenFilterFunction(search))
  const searched = _.uniq([
    ...result,
    ...tokens.filter((token) => {
      if (isAddress(search) || isAddress(search?.toLowerCase()))
        return token?.address?.toLowerCase() === search?.toLowerCase()

      return token?.name?.toLowerCase().includes(search) || token.symbol?.toLowerCase().includes(search)
    }),
  ])
  console.dir(searched)
  return searched
}

export function useSortedTokensByQuery(
  tokens: Token[] | undefined,
  searchQuery: string,
  showOnlyBlueberryCoins?: boolean
): Token[] {
  return useMemo(() => {
    if (!tokens) {
      return []
    }

    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)

    if (symbolMatch.length > 1) {
      return tokens
    }

    const exactMatches: Token[] = []
    const symbolSubtrings: Token[] = []
    const rest: Token[] = []
    const kibaCoin = new Token(1, '0x005d1123878fc55fbd56b54c73963b234a64af3c', 9, 'Kiba', 'Kiba Inu')
    // sort tokens by exact match -> subtring on symbol match -> rest
    tokens.forEach((token) => {
      if (token.symbol?.toLowerCase() === symbolMatch[0]) {
        exactMatches.push(token)
      } else if (token.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
        symbolSubtrings.push(token)
      } else if (token?.address?.toLowerCase() === searchQuery.toLowerCase().trim()) {
        exactMatches.push(token)
      } else if (!searchQuery) {
        rest.push(token)
      }
    })

    return _.uniqBy([...exactMatches, ...symbolSubtrings, ...rest], (a) => a.address)
  }, [tokens, searchQuery, showOnlyBlueberryCoins])
}
