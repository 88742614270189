import { RowBetween, RowFixed } from '../Row'
import { X } from 'react-feather'
import { Trans, t } from '@lingui/macro'
import styled, { css, ThemeContext } from 'styled-components/macro'
import { useContext, useRef, useState } from 'react'
import {
  useExpertModeManager,
  useSetAutoSlippage,
  useSetFrontrunProtectionEnabled,
  useUserDetectRenounced,
  useUserSingleHopOnly,
} from '../../state/user/hooks'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { ReactComponent as Wizard } from '../../../src/assets/svg/l4.svg'
import { ApplicationModal } from '../../state/application/actions'
import { AutoColumn } from '../Column'
import { ButtonPrimary } from '../Button'
import Modal from '../Modal'
import { Percent } from '@uniswap/sdk-core'
import QuestionHelper from '../QuestionHelper'
import ReactGA from 'react-ga'
import { TYPE } from '../../theme'
import { Text } from 'rebass'
import { NewToggle } from '../Toggle'
import TransactionSettings from '../TransactionSettings'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { FlyoutAlignment } from 'components/Menu'
import { ReactComponent as Settings } from 'assets/svg/settingsnew.svg'
import { opacify } from 'polished'
import { AndContentWrapper } from 'components/GasSelectorModal'

const StyledMenuIcon = styled(Settings)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.text2};
  z-index: 3;

  :hover {
    opacity: 0.7;
  }
`

export const StyledCloseIcon = styled(X)`
  height: 16px;
  width: 16px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`
const SettingsRow = styled(RowFixed)`
  padding: 0.5rem 0;
  width: 100%;
`

export const StyledMenuButton = styled.button`
  border-radius: 48px;
  height: 32px;
  width: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => opacify(-0.9, theme.text1)};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border: 1.5px solid ${({ theme }) => opacify(-0.9, theme.text1)};
  `};

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  > svg {
    position: absolute;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -0.3rem;
  left: 0.3rem;
  font-size: 14px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ResponsiveModal = styled(Modal)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: unset;
    right: 0;
    left: unset;
  `};
`

const ModalContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export default function SettingsTab({ placeholderSlippage }: { placeholderSlippage: Percent }) {
  const node = useRef<HTMLDivElement>(null)
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()
  const { onSwitchUseChangeRecipient } = useSwapActionHandlers()
  const { useOtherAddress } = useSwapState()
  const theme = useContext(ThemeContext)

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [useAutoSlippage, setUseAutoSlippage] = useSetAutoSlippage()
  const [useFrontRunProtection, setUseFrontRunProtection] = useSetFrontrunProtectionEnabled()
  const [useDetectRenounced, setUseDetectRenounced] = useUserDetectRenounced()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)
  const onDismissClick = () => setShowConfirmation(false)
  const toggleFunction = expertMode
    ? () => {
        toggleExpertMode()
        setShowConfirmation(false)
      }
    : () => {
        toggle()
        setShowConfirmation(true)
      }
  const toggleSlippageFunction = () => {
    ReactGA.event({
      category: 'Routing',
      action: useAutoSlippage ? 'disable auto slippage' : 'enable auto slippage',
    })
    setUseAutoSlippage(!useAutoSlippage)
  }
  const toggleFrontRunFunction = () => {
    ReactGA.event({
      category: 'Routing',
      action: useAutoSlippage ? 'enable front run protection' : 'disable front run protection',
    })
    setUseFrontRunProtection(!useFrontRunProtection)
  }
  const toggleSingleHopFn = () => {
    ReactGA.event({
      category: 'Routing',
      action: singleHopOnly ? 'disable single hop' : 'enable single hop',
    })
    setSingleHopOnly(!singleHopOnly)
  }

  const toggleDetectRenounced = () => {
    setUseDetectRenounced(!useDetectRenounced)
  }
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  const specifyReceiver = () => {
    onSwitchUseChangeRecipient(!useOtherAddress)
  }
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451

    <StyledMenu ref={node as any}>
      <Modal size={540} maxHeight={800} isOpen={showConfirmation} onDismiss={onDismissClick}>
        <AndContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <TYPE.modalHeader>Are you sure?</TYPE.modalHeader>
              <StyledCloseIcon onClick={onDismissClick} />
            </RowBetween>
            <AutoColumn gap="lg" style={{ padding: '0 2rem', alignItems: 'center' }}>
              <TYPE.modalBody>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </TYPE.modalBody>
              <TYPE.modalBody>ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.</TYPE.modalBody>

              <label>
                <TYPE.modalSubheader>
                  {' '}
                  <input
                    style={{ background: isAcknowledged ? 'theme.primary6' : '' }}
                    type="checkbox"
                    checked={isAcknowledged}
                    onChange={(e) => setIsAcknowledged(e.target.checked)}
                  />{' '}
                  &nbsp;&nbsp; I acknowledge that I have read the disclaimer and I know the risks of using Wizard Mode.
                </TYPE.modalSubheader>
              </label>

              <ButtonPrimary
                marginTop={20}
                disabled={!isAcknowledged} // Disable the button if not acknowledged
                onClick={() => {
                  toggleExpertMode()
                  setShowConfirmation(false)
                }}
              >
                <Text id="confirm-expert-mode">
                  <Trans>Use Wizard Mode</Trans>
                </Text>
              </ButtonPrimary>
            </AutoColumn>
          </AutoColumn>
        </AndContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        {expertMode ? null : <StyledMenuIcon />}
        {expertMode ? <Wizard /> : null}
      </StyledMenuButton>
      {open && (
        <ResponsiveModal size={360} isOpen={open} onDismiss={toggle}>
          <ModalContentWrapper ref={node}>
            <AutoColumn gap="md" style={{ padding: '2rem', display: 'inline-block', width: '100%' }}>
              <div style={{ paddingBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <TYPE.modalHeader>Settings</TYPE.modalHeader>
              </div>
              <div style={{ paddingBottom: '20px' }}>
                <TYPE.modalSubheader>Transaction Settings</TYPE.modalSubheader>
                <TransactionSettings placeholderSlippage={placeholderSlippage} />
              </div>
              <TYPE.modalSubheader>Interface Settings</TYPE.modalSubheader>
              <RowBetween>
                <SettingsRow>
                  <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                    <Trans>Toggle Expert Mode</Trans>
                  </TYPE.black>
                  <QuestionHelper
                    text={
                      <Trans>Allow high price impact trades and skip the confirm screen. Use at your own risk.</Trans>
                    }
                  />
                </SettingsRow>

                <NewToggle id="toggle-expert-mode-button" isActive={expertMode} toggle={toggleFunction} />
              </RowBetween>
              {/* <RowBetween>
                <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Use Auto Slippage</Trans>
                </TYPE.black>
                <QuestionHelper
                  text={
                    <Trans>Automatically calculate the lowest possible slippage on every swap you do. This is based on the token your trading and the smart contracts taxes.</Trans>
                  }
                />
              </RowFixed>
              <Toggle
                id="toggle-auto-slippage"
                isActive={useAutoSlippage}
                toggle={toggleSlippageFunction}
              />
              </RowBetween> */}
              {/* <RowBetween>
            <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Use Flash Bots Sandwich Protection</Trans>
                </TYPE.black>
                <QuestionHelper
                  text={
                    <Trans>Protect your self from front-runner bots that will buy and sell directly before and after your transaction. This feature is currently in BETA mode.</Trans>
                  }
                />
              </RowFixed>
              <Toggle
                id="toggle-frontrun-protection]"
                isActive={useFrontRunProtection}
                toggle={toggleFrontRunFunction}
              />
              </RowBetween>
                <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  <Trans>Disable Multihops</Trans>
                </TYPE.black>
                <QuestionHelper text={<Trans>Restricts swaps to direct pairs only.</Trans>} />
              </RowFixed>
              <Toggle
                id="toggle-disable-multihop-button"
                isActive={singleHopOnly}
                toggle={toggleSingleHopFn}
              />
            </RowBetween> */}
              <RowBetween>
                <SettingsRow>
                  <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                    <Trans>Detect Renouce</Trans>
                  </TYPE.black>
                  <QuestionHelper
                    text={
                      <Trans>
                        Adds an extra check to all the tokens you are swapping to determine if the contract has been
                        renounced. Will show a visual indicator in the UI.
                      </Trans>
                    }
                  />
                </SettingsRow>
                <NewToggle
                  id="toggle-detect-renounced-button"
                  isActive={useDetectRenounced}
                  toggle={toggleDetectRenounced}
                />
              </RowBetween>
              <RowBetween>
                <SettingsRow>
                  <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                    <Trans>Specify Reciever</Trans>
                  </TYPE.black>
                  <QuestionHelper
                    text={
                      <Trans>Enables the feature to allow you to specify a receiving address for your swaps.</Trans>
                    }
                  />
                </SettingsRow>
                <NewToggle id="toggle-disable-multihop-button" isActive={useOtherAddress} toggle={specifyReceiver} />
              </RowBetween>
            </AutoColumn>
          </ModalContentWrapper>
        </ResponsiveModal>
      )}
    </StyledMenu>
  )
}
