import React from 'react'
import styled from 'styled-components/macro'
import { LimitOrders } from 'state/transactions/hooks'
import { MEDIA_WIDTHS } from 'theme'

export const PageWrapper = styled.div`
  padding: 0px 0px 0px;
  max-width: 480px;
  width: 100%;
  margin-top: -40px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-top: 0px;
  }
`

// Mostly copied from `AppBody` but it was getting too hard to maintain backwards compatibility.

const LimitOrderPage: React.FC = () => {
  return (
    <PageWrapper>
      <LimitOrders />
    </PageWrapper>
  )
}

export default LimitOrderPage
