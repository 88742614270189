import Badge from 'components/Badge'
import React from 'react'
import { TYPE } from 'theme'
import useTheme from 'hooks/useTheme'
import { TokenSecurityData } from 'gopluslabs/goplus'

type Maybe<T> = T | undefined | null

type Props = {
  tokenSecurityData?: TokenSecurityData
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'end'
    | 'stretch'
    | 'start'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
}

export const SwapBuySellTax = (props: Props) => {
  const { tokenSecurityData, justifyContent } = props
  const theme = useTheme()
  const buyTax = tokenSecurityData?.buy_tax_amount
  const sellTax = tokenSecurityData?.sell_tax_amount
  const roundedBuyTax = buyTax ? Math.round(buyTax) : 0
  const roundedSellTax = sellTax ? Math.round(sellTax) : 0

  return (
    <React.Fragment>
      {Boolean(tokenSecurityData) && !Boolean(tokenSecurityData?.is_honeypot) && (
        <div style={{ padding: 2, display: 'flex', flexFlow: 'column wrap', justifyContent: 'end' }}>
          <div
            style={{
              justifyContent: justifyContent ?? 'flex-end',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row wrap',
              gap: 10,
            }}
          >
            <TYPE.small>
              <Badge style={{ color: theme.text1, fontSize: '.85rem', background: 'transparent' }}>
                <TYPE.currencyInputSub display="inline-block" fontSize={'.75rem'}>
                  B
                </TYPE.currencyInputSub>{' '}
                &nbsp; {roundedBuyTax}%
              </Badge>
            </TYPE.small>
            <div style={{ height: 20, width: 1.5, background: theme.text1, opacity: '40%' }}></div>
            <TYPE.small>
              <Badge style={{ color: theme.text1, fontSize: '.85rem', background: 'transparent' }}>
                <TYPE.currencyInputSub display="inline-block" fontSize={'.75rem'}>
                  S
                </TYPE.currencyInputSub>{' '}
                &nbsp; {roundedSellTax}%
              </Badge>
            </TYPE.small>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
