import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { GasIcon } from 'components/AndyComponents/icons'
import GasSelectorModal from 'components/GasSelectorModal'
import React, { useContext } from 'react'
import { ArrowUpRight } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import { useIsMobile } from 'pages/Swap/SelectiveCharting'
import { ReactComponent as GasIconNew } from 'assets/svg/gasiconnew.svg'
import { StyledMenuButton } from '../Settings'

const StyledSwapHeader = styled.div<{ isMobile?: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '4px 16px 20px 16px' : '0px 4px 16px 4px')};
  width: 100%;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg0};
`
const StyledMenuIcon = styled(GasIconNew)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.text2};

  :hover {
    opacity: 0.7;
  }
`

export default function SwapSubHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  const [gasSettingsOpen, setGasSettingsOpen] = React.useState(false)
  const openGasSettings = () => setGasSettingsOpen(true)
  const closeGasSettings = () => setGasSettingsOpen(false)
  const isMobile = useIsMobile()
  return (
    <StyledSwapHeader isMobile={isMobile}>
      <RowBetween>
        <GasSelectorModal isOpen={gasSettingsOpen} onDismiss={closeGasSettings} />
        <StyledMenuButton onClick={openGasSettings}>
          <StyledMenuIcon />
        </StyledMenuButton>
        <SettingsTab placeholderSlippage={allowedSlippage} />
      </RowBetween>
    </StyledSwapHeader>
  )
}
