import React, { useEffect, useState, useRef } from 'react'
import { SupportedChainId } from 'constants/chains'
import { TokenSecurityData } from './goplus'
import { loadTokenSecurity, isSafeToken } from './goplus'
import Modal from 'components/Modal'
import { ButtonOutlinedAnd } from 'components/Button'
import styled from 'styled-components/macro'
import theme, { TYPE } from 'theme'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { StyledCloseIcon } from 'components/Settings'
import { X } from 'react-feather'
import { SwapBuySellTax } from 'components/swap/SwapBuySellTax'
import { FlRow } from 'components/AndyComponents/Flex'
import { justifyContent } from 'styled-system'

interface TokenSecurityResultProps {
  chainId: SupportedChainId
  tokenAddress: string
}
const StyledContentWrapper = styled.div`
  padding: 1rem;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: none; // Hide scrollbar for Firefox

  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari and Opera
  }
`
const TokenSecurityResult: React.FC<TokenSecurityResultProps> = ({ chainId, tokenAddress }) => {
  const [tokenSecurityData, setTokenSecurityData] = useState<TokenSecurityData | undefined>()
  const [showModal, setShowModal] = useState(false)
  const node = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await loadTokenSecurity(chainId, tokenAddress)
        console.log('Data fetched:', data) // Log the fetched data
        setTokenSecurityData(data)
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchData()
  }, [chainId, tokenAddress])
  const prettyPrintJson = (data: TokenSecurityData | undefined): string[] => {
    if (!data) {
      return []
    }

    const lines: string[] = []

    const addLine = (key: string, value: any) => {
      lines.push(`${key}: ${value}`)
    }

    for (const key in data) {
      const value = data[key as keyof TokenSecurityData]

      if (Array.isArray(value)) {
        addLine(key, JSON.stringify(value, null, 2))
      } else if (typeof value === 'object' && value !== null) {
        addLine(key, JSON.stringify(value, null, 2))
      } else {
        addLine(key, value)
      }
    }

    return lines
  }

  const prettyJsonLines = prettyPrintJson(tokenSecurityData)
  const getUnsafeReasons = (data: TokenSecurityData): string[] => {
    const reasons: string[] = []

    if (!data.is_open_source) {
      reasons.push('Not open source')
    }

    if (data.is_airdrop_scam) {
      reasons.push('Airdrop scam')
    }

    if (data.is_true_token === false) {
      reasons.push('Not a true token')
    }

    if (data.sell_tax) {
      reasons.push(`Sell tax too high!: ${data.sell_tax_amount?.toFixed(0)}%`)
    }

    if (data.buy_tax) {
      reasons.push(`Buy tax too high!: ${data.buy_tax_amount?.toFixed(0)}%`)
    }

    if (data.owner_change_balance) {
      reasons.push('Owner can change balance')
    }

    if (data.is_honeypot) {
      reasons.push('Honeypot')
    }

    return reasons
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const Circle = styled.div`
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.white};
    transition: transform 0.3s ease;
  `

  return (
    <div
      style={{
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        paddingTop: 20,
      }}
    >
      {tokenSecurityData ? (
        <>
          <div>
            {isSafeToken(tokenSecurityData) ? (
              <ButtonOutlinedAnd onClick={toggleModal}>
                <Circle
                  style={{
                    backgroundColor: `#00BD13`,
                  }}
                />
                Is Safe?{' '}
              </ButtonOutlinedAnd>
            ) : (
              <ButtonOutlinedAnd style={{ borderColor: '#FF0000' }} onClick={toggleModal}>
                <Circle
                  style={{
                    backgroundColor: `#FF0000`,
                  }}
                />
                Not Safe!{' '}
              </ButtonOutlinedAnd>
            )}
          </div>
          <div style={{ flex: 1, padding: 2, display: 'flex', flexFlow: 'column wrap', justifyContent: 'end' }}>
            <div
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'row wrap',
                gap: 0,
              }}
            >
              {' '}
              <TYPE.currencyInputSub style={{ marginRight: 8 }}>
                {tokenSecurityData.token_symbol}&nbsp;Taxes
              </TYPE.currencyInputSub>
              <SwapBuySellTax tokenSecurityData={tokenSecurityData} />
            </div>
          </div>
          <Modal isOpen={showModal} onDismiss={toggleModal}>
            <StyledContentWrapper ref={node as any}>
              <AutoColumn gap="lg">
                <RowBetween style={{ padding: '0 2rem' }}>
                  <div>
                    <StyledCloseIcon onClick={toggleModal} />
                    <TYPE.modalHeader>Token Security Data</TYPE.modalHeader>
                    {!isSafeToken(tokenSecurityData) && (
                      <>
                        <TYPE.modalSubheader>Reason(s) for being marked as unsafe:</TYPE.modalSubheader>
                        <ul>
                          {getUnsafeReasons(tokenSecurityData).map((reason, index) => (
                            <li key={index}>{reason}</li>
                          ))}
                        </ul>
                      </>
                    )}
                    <ul>
                      <TYPE.modalBody>
                        {prettyJsonLines.map((line, index) => (
                          <li key={index}>{line}</li>
                        ))}
                      </TYPE.modalBody>
                    </ul>{' '}
                  </div>
                </RowBetween>
              </AutoColumn>
            </StyledContentWrapper>
          </Modal>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default TokenSecurityResult
