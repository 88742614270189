import React, { useEffect, useState } from 'react'
import { getTaxesForBscToken, getTokenTaxes } from 'pages/HoneyUtils'

import { JsonRpcProvider } from '@ethersproject/providers'
import { getMaxes } from 'pages/HoneyPotDetector'
import { useActiveWeb3React } from 'hooks/web3'
import { useWeb3Endpoint } from './PairSearch'

export const useBuySellTax = (tokenAddress: string, network: string) => {
  const [state, setState] = useState<{
    buy: number | undefined | null
    sell: number | undefined | null
    honeypot: boolean | undefined | null
  }>()
  const formattedNetwork = network == 'ethereum' ? 'eth' : 'bsc' ? 'bsc2' : 'bsc2'
  const fragment = getMaxes
  useEffect(() => {
    if (network === 'bsc' || network === 'bsc2' || network === 'ethereum' || network === 'eth') {
      console.log(`[useBuySellTax] - fetching fragment of taxes / honeypot status`)
      fragment(tokenAddress, formattedNetwork).then((response) => {
        setState({
          buy: response?.BuyTax ? Math.round(response.BuyTax) : 0,
          sell: response?.SellTax ? Math.round(response.SellTax) : 0,
          honeypot: response?.IsHoneypot,
        })
      })
    }
  }, [network, tokenAddress])

  if (network !== 'bsc' && network !== 'bsc2' && network !== 'ethereum' && network !== 'eth') return

  return state
}
